.footer {
  background-color: #212529;
  padding: 25px 50px;
  text-align: center;
}
a.social {
  margin: 0;
  transition: transform 250ms;
  display: inline-block;
}
a.social:hover {
  margin: 0;
  transform: translateY(-2px);
}
a.instagram {
  color: #d6249f;
}
.footerflex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.termsinfooter {
  padding: 5px;
  text-decoration: underline;
  cursor: pointer;
  color: white;
}
.termsinfooter:hover {
  color: white;
}
