@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@800&family=Lato:wght@100&display=swap');

.max-width {
  margin-top: 3%;
}
.about {
  font-family: 'Poppins', sans-serif;
  color: white;
}
.beza {
  font-size: larger;
}
.aboutContect {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}


.left img {
  height: 500px;
  width: 400px;
  object-fit: cover;
  border-radius: 6px;
}
.right {
  width: 40%;
}

@media (max-width: 690px) {
  .right {
    width: 100%;
  }
  .right p {
    text-align: justify;
    width: 90%;
    margin-left: 5%;
  }
}

.right p {
  padding: 5px;
  font-weight: 600;
  font-style: italic;
}
.heroabout {
  height: 40vh;
  background-image: url('../image/model33.jpeg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  opacity: 0.1;
}
