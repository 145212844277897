@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@800&family=Lato:wght@100&display=swap');

.home-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.home-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.items-list-wrapper {
  margin-top: 36px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.item-card {
  width: 300px;
  margin: 0 10px 32px;
  cursor: pointer;
}
.item-card p {
  letter-spacing: 0.3em;
  margin: 0 0 4px;
  color: #c38b72;
  text-align: center;
  font-family: 'Bodoni Moda', serif;
}

.item-list-image {
  border: 4px solid #2e1503;
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.cardEffect {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}
.cardEffect:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.header {
  width: 100%;
  text-align: Center;
  letter-spacing: 0.5em;
  color: white;
  font-family: 'Bodoni Moda', serif;
  margin: 0 auto 30px;
  font-size: 1vw;
}
.header p {
  font-size: 12px;
}
.btnBox {
  height: 33.33%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3%;
}
.btn {
  outline: none;
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
  letter-spacing: 0.2px;
  color: #fff;
  border: 2px solid white;
  padding: 2px;
}
.btn:hover {
  border: #c38b72;
  background-color: white;
  color: #c38b72;
}

.hero-image {
  height: 40vh;
  background-image: url('../image/model33.jpeg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  opacity: 0.1;
}
