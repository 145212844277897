.home-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.home-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.items-list-wrapper {
  margin-top: 36px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.item-card {
  width: 300px;
  margin: 0 10px 32px;
  cursor: pointer;
}
.item-card h5 {
  letter-spacing: 0.3em;
  margin: 0 0 4px;
  text-align: left;
  color: #c38b72;
  text-align: center;
  font-family: 'Bodoni Moda', serif;
}

.item-list-image {
  width: 100%;
  border-radius: 10px;
}

.item-list-images {
  width: 100%;
}
.cardEffect {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);

  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}
.cardEffect:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.header {
  width: 100%;
  text-align: Center;
  letter-spacing: 0.5em;
  color: white;
  font-family: 'Bodoni Moda', serif;
  margin: 0 auto 30px;
  font-size: 1vw;
}

.serviceBtn {
  width: 50%;
  margin-left: 25%;
  border-radius: 10px;
  background: #c38b72;
  border: solid 2px white;
  color: #fff;
}
.serviceBtn:hover {
  background: white;
  border: solid 2px #c38b72;
  color: #c38b72;
}
.info p {
  width: 100%;
  color: white;
  font-size: 12px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.text {
  width: 60%;
  border: 3px solid #4f4f4f;
  background-color: #fff;
  text-align: center;
  padding: 4%;
  margin: 0 auto;
  padding: 5x;
}
.text p {
  text-align: left;
}
.hero-image {
  height: 40vh;
  background-image: url('../image/model11.jpeg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
h5 {
  font-size: 1rem;
}
