.termpage {
  width: 80%;
  color: white;
  margin: 0 auto;
}

.divOne {
  margin-top: 5%;
  width: 90%;
  margin: 0 auto;
}
.termHeader {
  margin-top: 5%;
  width: 100%;
  text-align: center;
}
.agree {
  font-style: italic;
  color: white;
  margin-left: 5%;
  display: flex;
  justify-content: center;
}
.thankyou {
  font-style: bold;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  color: white;
  padding-bottom: 5%;
}
