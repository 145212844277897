.successPage {
  margin-top: 5%;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.success {
  width: 75%;
  margin: 0 auto;
  display: flex;
  font-size: 1vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid #c38b72;
  text-align: center;
  padding: 4%;
}

.success h1 {
  font-weight: bold;
  font-size: 0.8rem;
}

.success p {
  font-size: 12px;
  padding: 0px 0px 20px 20px;
}
