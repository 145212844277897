.contact_form .form-control {
  padding: 1.375rem 0.75rem;
  line-height: 1.5;
  color: aliceblue;
  background-color: black;
  border-radius: 0 !important;
  border: 1px solid white;
}
span {
  color: red;
}
.contact_form .form-control {
  padding: 1.375rem 0.75rem;
  line-height: 1.5;
  color: aliceblue;
  background-color: black;
  border-radius: 0 !important;
  border: 1px solid white;
}
.contact_form input.form-control {
  margin-bottom: 2em;
  height: calc(2.5 + 0.75rem + 2px);
}

input {
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  background: #fff;
  margin-bottom: 2%;
  border: 1px solid #ccc;
  padding: 1%;
  color: #555;
}
.mailto{
  color:#c38b72;
  text-decoration: none;
}
.mailto:hover{
text-decoration: underline;
color: #fff;
}
